import { HttpParams } from '@angular/common/http';

export function buildHttpParams<T extends object>(
  obj?: T
): HttpParams | undefined {
  let params = new HttpParams();

  if (typeof obj !== 'object') {
    return undefined;
  }

  Object.keys(obj).forEach((key: string) => {
    const rawValue = (obj as Record<string, unknown>)[key];
    const value =
      typeof rawValue === 'string' ? (rawValue as string).trim() : rawValue;

    if (value || value === false) {
      if (Array.isArray(value)) {
        value.forEach((data) => (params = params.append(`${key}[]`, data)));
      } else if (['string', 'number', 'boolean'].includes(typeof value)) {
        params = params.append(`${key}`, value as string | number | boolean);
      } else {
        if (Object.prototype.hasOwnProperty.call(value, 'toString')) {
          params = params.append(`${key}`, value.toString());
        } else {
          params = params.append(`${key}`, JSON.stringify(value));
        }
      }
    }
  });

  return params;
}
