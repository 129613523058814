import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  KanautoCardFindRequestDto,
  KanautoCardFindResponseDto,
  KanautoCardCreateRequestDto,
  KanautoCardDto,
  KanautoCardUpdateRequestDto,
  KanautoCardAvailableFindResponseDto,
  UploadCardFileRequestDto,
} from './dtos';
import { Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import {
  snackBarApiErrorFallback,
  snackBarSuccessMessage,
} from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class KanautoCardApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  /** Поиск карт */
  find(
    request: KanautoCardFindRequestDto
  ): Observable<KanautoCardFindResponseDto> {
    return this.http
      .get<KanautoCardFindResponseDto>('/tes-crm/kanauto-card', {
        params: buildHttpParams(request),
      })
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка карт'
        )
      );
  }

  /** Добавление карты */
  create(request: KanautoCardCreateRequestDto): Observable<KanautoCardDto> {
    return this.http
      .post<KanautoCardDto>('/tes-crm/kanauto-card', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Карта успешно сохранена'),
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при сохранении карты')
      );
  }

  /** Получение карты */
  get(id: string): Observable<KanautoCardDto | null> {
    return this.http
      .get<KanautoCardDto>(`/tes-crm/kanauto-card/${id}`)
      .pipe(
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при получении карты')
      );
  }

  /** Обновление карты */
  update(
    id: string,
    request: KanautoCardUpdateRequestDto
  ): Observable<KanautoCardDto> {
    return this.http
      .put<KanautoCardDto>(`/tes-crm/kanauto-card/${id}`, request)
      .pipe(snackBarSuccessMessage(this.snackBar, 'Карта успешно сохранена'));
  }

  /** Удаление карты */
  delete(id: string): Observable<KanautoCardDto> {
    return this.http
      .delete<KanautoCardDto>(`/tes-crm/kanauto-card/${id}`)
      .pipe(
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при удалении карты')
      );
  }

  /** Добавление нескольких карт */
  createMany(
    request: KanautoCardCreateRequestDto[]
  ): Observable<KanautoCardDto[]> {
    return this.http
      .post<KanautoCardDto[]>('/tes-crm/kanauto-card/array', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Карты успешно сохранены'),
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при добавлении карт')
      );
  }

  getAvailableCards(
    request: KanautoCardFindRequestDto
  ): Observable<KanautoCardAvailableFindResponseDto> {
    return this.http
      .get<KanautoCardAvailableFindResponseDto>(
        '/tes-crm/kanauto-card/available-cards',
        {
          params: buildHttpParams(request),
        }
      )
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка карт'
        )
      );
  }

  uploadCardByCSV(request: FormData): Observable<unknown> {
    return this.http
      .post('/tes-crm/kanauto-card/from-csv', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Карта успешно добавлена'),
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при добавлении карты')
      );
  }
}
