import { catchError, Observable, tap, throwError } from 'rxjs';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { HttpErrorResponseShowed } from '@app/core/snack-bar/snack-bar-common-api-error-interceptor.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Выводит сообщение в случае непоказанной ранее ошибки запроса
 * @param snackBar
 * @param defaultMessage
 */
export function snackBarApiErrorFallback<T>(
  snackBar: SnackBarService,
  defaultMessage: string
) {
  return (source: Observable<T>) =>
    source.pipe(
      catchError((error) => {
        if (
          !(error instanceof HttpErrorResponseShowed) &&
          error instanceof HttpErrorResponse
        ) {
          console.error(error);
          const message = Array.isArray(error.error.message)
            ? error.error.message[0]
            : error.error.message;
          snackBar.showDangerMessage(message || defaultMessage);
          return throwError(() => HttpErrorResponseShowed.clone(error));
        } else {
          snackBar.showDangerMessage(defaultMessage);
        }

        return throwError(error);
      })
    );
}

/**
 * Отображает сообщение в случае успешного запроса
 * @param snackBar
 * @param message
 */
export function snackBarSuccessMessage<T>(
  snackBar: SnackBarService,
  message = 'Данные успешно сохранены'
) {
  return (source: Observable<T>) =>
    source.pipe(tap(() => snackBar.showSuccessMessage(message)));
}
