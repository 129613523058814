import {
  APP_INITIALIZER,
  FactoryProvider,
  Injectable,
  NgZone,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  PRIMARY_OUTLET,
  ResolveEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { tap } from 'rxjs';

/**
 * ```
 * @NgModule({
 *   providers: [
 *     providePrimaryOutletPreserveQuery(),
 *   ],
 * })
 * export class AppModule {}
 * ```
 */
export function providePrimaryOutletPreserveQuery(): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (service: PrimaryOutletPreserveQueryService) => () =>
      service.watch(),
    deps: [PrimaryOutletPreserveQueryService],
    multi: true,
  };
}

/**
 * Сохранение query параметров при переходе во вспомогательные аутлеты.
 */
@Injectable({
  providedIn: 'root',
})
export class PrimaryOutletPreserveQueryService {
  /**
   * Находится ли текущий роут в главном аутлете.
   *
   * В процессе изменения пути показывает состояние роута,
   * с которого выполняется переход.
   */
  get inPrimary(): boolean | null {
    return this._inPrimary;
  }

  /**
   * Находится ли следующий роут в главном аутлете.
   *
   * В процессе изменения пути, начиная с RoutesRecognized,
   * отображает состояние роута, на который осуществляется переход.
   */
  get inPrimaryNext(): boolean | null {
    return this._inPrimaryNext;
  }

  protected _inPrimary: boolean | null = null;
  protected _inPrimaryNext: boolean | null = null;

  protected pendingQuery: Params | null = null;
  protected preservedQuery: Params | null = null;

  constructor(
    protected readonly ngZone: NgZone,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute
  ) {}

  /**
   * Подписка на события роутера.
   *
   * 1. В момент начала перехода (NavigationStart) временно запоминает query;
   *
   * 2. Когда роут распознан (RoutesRecognized), устанавливает isPrimaryNext;
   *
   * 3. После фазы Resolve (ResolveEnd) при переходе из главного
   * в вспомогательный аутлет сохраняет временный query (п. 1);
   *
   * 4. В момент завершения навигации (NavigationEnd) устанавливает isPrimary,
   * очищает временные значения и при возвращении из вспомогательного в главный аутлет
   * восстанавливает последние сохранённые query параметры.
   */
  watch(): void {
    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            this.handleNavigationStart(
              this.activatedRoute.snapshot.queryParams
            );
          } else if (event instanceof RoutesRecognized) {
            this.handleRoutesRecognized(event.state.root);
          } else if (event instanceof ResolveEnd) {
            this.handleResolveEnd();
          } else if (event instanceof NavigationEnd) {
            this.handleNavigationEnd();
          } else if (
            event instanceof NavigationCancel ||
            event instanceof NavigationError
          ) {
            this.reset();
          }
        })
      )
      .subscribe();
  }

  /**
   * Находится ли роут в главном аутлете.
   *
   * @param route
   */
  routeInPrimaryOutlet(route: ActivatedRouteSnapshot): boolean {
    return (
      this.routeParentsInPrimaryOutlet(route) &&
      this.routeChildrenInPrimaryOutlet(route)
    );
  }

  protected routeChildrenInPrimaryOutlet(
    route: ActivatedRouteSnapshot
  ): boolean {
    if (route.outlet !== PRIMARY_OUTLET) {
      return false;
    }

    for (const child of route.children) {
      if (!this.routeChildrenInPrimaryOutlet(child)) {
        return false;
      }
    }

    return true;
  }

  protected routeParentsInPrimaryOutlet(
    route: ActivatedRouteSnapshot
  ): boolean {
    if (route.outlet !== PRIMARY_OUTLET) {
      return false;
    }

    if (route.parent) {
      return this.routeParentsInPrimaryOutlet(route.parent);
    }

    return true;
  }

  protected handleNavigationStart(queryParams: Params): void {
    this.pendingQuery = queryParams;
  }

  protected handleRoutesRecognized(event: ActivatedRouteSnapshot): void {
    this._inPrimaryNext = this.routeInPrimaryOutlet(event);
  }

  protected handleResolveEnd(): void {
    if (
      this._inPrimaryNext !== this._inPrimary &&
      !this._inPrimaryNext &&
      this.isQueryNotEmpty(this.pendingQuery)
    ) {
      this.preservedQuery = this.pendingQuery;
    }
  }

  protected handleNavigationEnd(): void {
    if (
      this._inPrimaryNext !== this._inPrimary &&
      this._inPrimaryNext &&
      this.isQueryNotEmpty(this.preservedQuery)
    ) {
      this.ngZone.run(() =>
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: this.preservedQuery,
          queryParamsHandling: 'merge',
        })
      );

      this.preservedQuery = null;
    }

    this._inPrimary = this._inPrimaryNext;
    this.pendingQuery = null;
  }

  reset(): void {
    this._inPrimaryNext = this._inPrimary;
    this.pendingQuery = null;
  }

  protected isQueryNotEmpty(query?: Params | null): query is Params {
    return !!query && Object.values(query).length > 0;
  }
}
