import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  UserCreateRequestDto,
  UserDto,
  UserFindRequestDto,
  UserFindResponseDto,
  UserUpdateRequestDto,
} from './dtos';
import { map, Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import { cleanPassword } from '@app/core/api/user/mappers';
import {
  snackBarApiErrorFallback,
  snackBarSuccessMessage,
} from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  /** Поиск пользователей */
  find(request: UserFindRequestDto): Observable<UserFindResponseDto> {
    return this.http
      .get<UserFindResponseDto>('/tes-crm/users', {
        params: buildHttpParams(request),
      })
      .pipe(
        map((res) => ({
          ...res,
          rows: res.rows?.map(cleanPassword),
        })),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка пользователей'
        )
      );
  }

  /** Добавление пользователя */
  create(request: UserCreateRequestDto): Observable<UserDto> {
    return this.http
      .post<UserDto>('/tes-crm/users', request)
      .pipe(
        map(cleanPassword),
        snackBarSuccessMessage(this.snackBar, 'Пользователь успешно сохранён'),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при добавлении пользователя'
        )
      );
  }

  /** Получение пользователя */
  get(id: string): Observable<UserDto | null> {
    return this.http
      .get<UserDto>(`/tes-crm/users/${id}`)
      .pipe(
        map(cleanPassword),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении пользователя'
        )
      );
  }

  /** Обновление пользователя */
  update(id: string, request: UserUpdateRequestDto): Observable<UserDto> {
    return this.http
      .put<UserDto>(`/tes-crm/users/${id}`, request)
      .pipe(
        map(cleanPassword),
        snackBarSuccessMessage(this.snackBar, 'Пользователь успешно сохранён')
      );
  }

  /** Удаление пользователя */
  delete(id: string): Observable<UserDto> {
    return this.http
      .delete<UserDto>(`/tes-crm/users/${id}`)
      .pipe(
        map(cleanPassword),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при удалении пользователя'
        )
      );
  }

  /** Добавление нескольких пользователей */
  createMany(request: UserUpdateRequestDto[]): Observable<UserDto[]> {
    return this.http.post<UserDto[]>('/tes-crm/users/array', request).pipe(
      map((els) => els.map(cleanPassword)),
      snackBarSuccessMessage(this.snackBar, 'Пользователи успешно сохранены'),
      snackBarApiErrorFallback(
        this.snackBar,
        'Ошибка при добавлении пользователей'
      )
    );
  }
}
