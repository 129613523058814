import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthTokenStateActions } from './auth-token.state.actions';

export interface AuthTokenStateModel {
  accessToken: string | null;
  hostAccessToken: string | null;
}

const EMPTY_STATE: AuthTokenStateModel = {
  accessToken: null,
  hostAccessToken: null,
};

@State<AuthTokenStateModel>({
  name: 'tokens',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthTokenState {
  @Selector()
  static accessToken(state: AuthTokenStateModel): string | null {
    return state.accessToken;
  }

  @Selector()
  static authenticated(state: AuthTokenStateModel): boolean {
    return !!state.accessToken;
  }

  @Action(AuthTokenStateActions.Login)
  login(
    { patchState }: StateContext<AuthTokenStateModel>,
    { token }: AuthTokenStateActions.Login
  ): void {
    patchState({ accessToken: token, hostAccessToken: null });
  }

  @Action(AuthTokenStateActions.Logout)
  logout({ setState, getState }: StateContext<AuthTokenStateModel>): void {
    const state = getState();
    const wasAuthorized = !!state.accessToken;

    setState({ ...EMPTY_STATE });

    if (wasAuthorized) {
      window.location.reload();
    }
  }

  @Action(AuthTokenStateActions.Impersonate)
  impersonate(
    { getState, patchState }: StateContext<AuthTokenStateModel>,
    { token }: AuthTokenStateActions.Impersonate
  ): void {
    const state = getState();

    patchState({
      accessToken: token,
      hostAccessToken: state.hostAccessToken ?? state.accessToken ?? null,
    });
  }

  @Action(AuthTokenStateActions.ClearImpersonation)
  clearImpersonation({
    getState,
    patchState,
  }: StateContext<AuthTokenStateModel>): void {
    const state = getState();

    patchState({
      accessToken: state.hostAccessToken || null,
      hostAccessToken: null,
    });
  }
}
