import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import {
  OrderAggregationDto,
  OrderFindRequestDto,
  OrderFindResponseDto,
} from '@app/core/api/order/dtos';
import { Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  find<A extends keyof OrderAggregationDto | never = never>(
    request: OrderFindRequestDto
  ): Observable<OrderFindResponseDto<A>> {
    return this.http
      .get<OrderFindResponseDto<A>>('/tes-crm/order', {
        params: buildHttpParams(request),
      })
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка заказов'
        )
      );
  }

  aggregateFind<T extends keyof OrderAggregationDto>(aggregate: T[]) {
    return (request: Omit<OrderFindRequestDto, 'aggregate'>) =>
      this.find<T>({
        ...request,
        aggregate,
      });
  }
}
