import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  catchError,
  concatMap,
  map,
  Observable,
  of,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { AuthTokenState } from '../states/auth-token.state';
import { AuthTokenStateActions } from '../states/auth-token.state.actions';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return of(true).pipe(
      withLatestFrom(this.store.select(AuthTokenState.accessToken)),
      map(([_, token]) => this.addToken(req, token)),
      concatMap((req) => next.handle(req)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            this.store.dispatch(new AuthTokenStateActions.Logout());
          }
        }

        return throwError(error);
      })
    );
  }

  addToken(
    req: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    const alreadyHasToken = !!req.headers.get('authorization');

    return !token || alreadyHasToken
      ? req
      : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
