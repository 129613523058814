import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReloadControlService } from '@app/core/reload-control/reload-control.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ReloadControlService],
})
export class ReloadControlModule {}
