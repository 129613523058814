import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { PendingChangesPageComponent } from './pending-changes-page.component';
import { ConfirmationService } from '@tes/common';
import { PENDING_CHANGES_CONFIRMATION_QUESTION } from '@app/core/navigation/pending-changes-control/pending-changes.consts';

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<PendingChangesPageComponent>
{
  constructor(private readonly confirmationService: ConfirmationService) {}

  canDeactivate(
    component: PendingChangesPageComponent
  ): boolean | Observable<boolean> {
    return component.hasPendingChanges().pipe(
      switchMap((hasPendingChanges) =>
        hasPendingChanges
          ? this.confirmationService.confirm({
              question: PENDING_CHANGES_CONFIRMATION_QUESTION,
              confirmLabel: 'Закрыть',
              cancelLabel: 'Отмена',
            })
          : of(true)
      )
    );
  }
}
