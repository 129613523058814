import { State } from '@ngxs/store';
import { AuthProfileState } from './auth-profile.state';
import { AuthTokenState } from './auth-token.state';
import { Injectable } from '@angular/core';

@State({
  name: 'auth',
  children: [AuthProfileState, AuthTokenState],
})
@Injectable()
export class AuthState {}
