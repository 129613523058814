import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthGuard } from './core/auth/guards/not-auth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NotFoundPageComponent } from '@app/pages-core/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages-core/login-page/login-page.module').then(
        (m) => m.LoginPageModule
      ),
    canActivate: [NotAuthGuard],
    canLoad: [NotAuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import(
        './pages-core/main-container-page/main-container-page.module'
      ).then((m) => m.MainContainerPageModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
