import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, Subject } from 'rxjs';
import { ReloadControlProvider } from '@app/core/reload-control/reload-control.provider';

@Injectable()
export class DrawerReloadControlService implements ReloadControlProvider {
  drawerClosedSubject = new Subject<boolean>();

  handleDrawerOpened(): void {
    this.drawerClosedSubject.next(false);
  }

  handleDrawerClosed(): void {
    this.drawerClosedSubject.next(true);
  }

  shouldReloadData(): Observable<boolean> {
    return this.drawerClosedSubject.asObservable().pipe(distinctUntilChanged());
  }
}
