/**
 * Статус карты КАН Авто:
 * * 0 - Создана
 * * 1 - Активирована
 * * 2 - Удалена
 * * 3 - Просрочена
 */
export type KanautoCardStatus = 0 | 1 | 2 | 3;

export interface KanautoCardDto {
  /** Номер карты */
  number: string;
  /** VIN автомобиля */
  vin?: string | null;
  /** Код тарифа */
  tariffIdent?: string | null;
  /** Статус карты */
  status: KanautoCardStatus;
  /** Идентификатор клиента */
  clientId?: string | null;
  /** Номер телефона клиента */
  clientPhone?: string | null;
  /** Дата истечения срока, ISO */
  expAt?: string | null;
  /** Дата активации, ISO */
  activatedAt?: string | null;
  /** Дата продажи, ISO */
  soldAt?: string | null;
}

export interface KanautoAvailableCardCsvDto {
  /** Номер карты */
  cardNumber: string;
  /** Внешний идентификатор */
  externalId: number;
  /** Тариф */
  tariffIdent: KanautoTariffIdent;
}

export enum KanautoTariffIdent {
  BASE = 'kan_card_base',
  PREMIUM = 'kan_card_premium',
}
