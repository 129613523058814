<div class="confirmation-dialog">
  <div class="confirmation-dialog__close-wrapper">
    <button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="confirmation-dialog__body">
    <div class="confirmation-dialog__question">{{ data.question }}</div>
    <div class="confirmation-dialog__button-container">
      <button mat-flat-button (click)="cancel()">
        {{ data.cancelLabel }}
      </button>
      <button mat-flat-button color="primary" (click)="confirm()">
        {{ data.confirmLabel }}
      </button>
    </div>
  </div>
</div>
