import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageListUrlRequestDto } from './dtos/storage-list-url.request.dto';
import { Observable } from 'rxjs';
import { StorageListUrlResponseDto } from './dtos/storage-list-url.response.dto';
import { StorageFileUploadRequestDto } from './dtos/storage-file-upload.request.dto';
import { StorageFileUploadResponseDto } from './dtos/storage-file-upload.response.dto';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class StorageApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackbar: SnackBarService
  ) {}

  getUrlList(
    request: StorageListUrlRequestDto
  ): Observable<StorageListUrlResponseDto> {
    return this.http
      .post<StorageListUrlResponseDto>(`/tes-crm/storage/list-url`, request)
      .pipe(
        snackBarApiErrorFallback(
          this.snackbar,
          'Ошибка при получении списка файлов'
        )
      );
  }

  uploadFile(
    request: StorageFileUploadRequestDto
  ): Observable<StorageFileUploadResponseDto> {
    const formData = new FormData();

    formData.append('path', request.path);
    formData.append('file', request.file);

    return this.http
      .post<StorageFileUploadResponseDto>(
        `/tes-crm/storage/file-upload`,
        formData
      )
      .pipe(
        snackBarApiErrorFallback(this.snackbar, `Не удалось загрузить файл`)
      );
  }
}
