import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { TextFieldAutocompleteResponseDto } from '@app/core/api/text-field-autocomplete/dtos/text-field-autocomplete.response.dto';
import { TextFieldAutocompleteRequestDto } from '@app/core/api/text-field-autocomplete/dtos/text-field-autocomplete.request.dto';

@Injectable({
  providedIn: 'root',
})
export class TextFieldAutocompleteApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  find(
    request: TextFieldAutocompleteRequestDto
  ): Observable<TextFieldAutocompleteResponseDto> {
    return this.http
      .get<TextFieldAutocompleteResponseDto>(request.url, {
        params: buildHttpParams(request.params),
      })
      .pipe(snackBarApiErrorFallback(this.snackBar, 'Ошибка поиска'));
  }
}
