import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ReloadControlProvider } from '@app/core/reload-control/reload-control.provider';
import { distinctUntilChanged, map, Observable, skip } from 'rxjs';
import { AuthProfileState } from '@app/core/auth/states/auth-profile.state';

@Injectable({
  providedIn: 'root',
})
export class AuthReloadControlService implements ReloadControlProvider {
  constructor(private readonly store: Store) {}

  shouldReloadData(): Observable<boolean> {
    return this.store.select(AuthProfileState.userId).pipe(
      skip(1),
      distinctUntilChanged(),
      map(() => true)
    );
  }
}
