import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ServiceCreateRequestDto,
  ServiceDto,
  ServiceFindRequestDto,
  ServiceFindResponseDto,
  ServiceUpdateRequestDto,
} from './dtos';
import { Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import {
  snackBarApiErrorFallback,
  snackBarSuccessMessage,
} from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { ServiceAggregationDto } from '@app/core/api/service/dtos/service-aggregation.dto';

@Injectable({
  providedIn: 'root',
})
export class ServiceApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  /** Поиск услуг */
  find<A extends keyof ServiceAggregationDto | never = never>(
    request: ServiceFindRequestDto
  ): Observable<ServiceFindResponseDto<A>> {
    return this.http
      .get<ServiceFindResponseDto<A>>('/tes-crm/service', {
        params: buildHttpParams(request),
      })
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка услуг'
        )
      );
  }

  aggregateFind<T extends keyof ServiceAggregationDto>(aggregate: T[]) {
    return (request: Omit<ServiceFindRequestDto, 'aggregate'>) =>
      this.find<T>({
        ...request,
        aggregate,
      });
  }

  /** Добавление услуги */
  create(request: ServiceCreateRequestDto): Observable<ServiceDto> {
    return this.http
      .post<ServiceDto>('/tes-crm/service', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Услуга успешно сохранена'),
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при добавлении услуги')
      );
  }

  /** Получение услуги */
  get(id: string): Observable<ServiceDto | null> {
    return this.http
      .get<ServiceDto>(`/tes-crm/service/${id}`)
      .pipe(
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при получении услуги')
      );
  }

  /** Обновление услуги */
  update(id: string, request: ServiceUpdateRequestDto): Observable<ServiceDto> {
    return this.http
      .put<ServiceDto>(`/tes-crm/service/${id}`, request)
      .pipe(snackBarSuccessMessage(this.snackBar, 'Услуга успешно сохранена'));
  }

  /** Удаление услуги */
  delete(id: string): Observable<ServiceDto> {
    return this.http
      .delete<ServiceDto>(`/tes-crm/service/${id}`)
      .pipe(
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при удалении услуги')
      );
  }

  /** Добавление нескольких услуг */
  createMany(request: ServiceUpdateRequestDto[]): Observable<ServiceDto[]> {
    return this.http
      .post<ServiceDto[]>('/tes-crm/service/array', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Услуги успешно сохранены'),
        snackBarApiErrorFallback(this.snackBar, 'Ошибка при добавлении услуг')
      );
  }
}
