import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { ApiModule } from './api/api.module';
import { PendingChangesModule } from './navigation/pending-changes-control/pending-changes.module';
import { SnackBarModule } from './snack-bar/snack-bar.module';
import { ReloadControlModule } from './reload-control/reload-control.module';
import { DrawerModule } from '@app/core/navigation/drawer/drawer.module';
import { PreferencesModule } from '@app/core/preferences/preferences.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ru } from 'date-fns/locale';
import { NavigationQueryService } from './navigation/navigation-query.service';
import { providePrimaryOutletPreserveQuery } from './navigation/primary-outlet-preserve-query.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    ApiModule,
    PendingChangesModule,
    ReloadControlModule,
    SnackBarModule,
    DrawerModule.forRoot(),
    PreferencesModule,
  ],
  providers: [
    NavigationQueryService,
    providePrimaryOutletPreserveQuery(),
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: ru,
    },
  ],
})
export class CoreModule {}
