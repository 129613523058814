import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PreferencesStateActions } from '@app/core/preferences/store/preferences.state.actions';

interface PreferencesStateModel {
  sidenavPinned: boolean;
}

const EMPTY_STATE: PreferencesStateModel = {
  sidenavPinned: true,
};

@State<PreferencesStateModel>({
  name: 'preferences',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class PreferencesState {
  @Selector()
  static sidenavPinned(state: PreferencesStateModel): boolean {
    return state.sidenavPinned;
  }

  @Action(PreferencesStateActions.ToggleSidenavPinned)
  toggleSidenavPinned({
    getState,
    patchState,
  }: StateContext<PreferencesStateModel>): void {
    const state = getState();
    patchState({
      sidenavPinned: !state.sidenavPinned,
    });
  }
}
