import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { catchError, Observable, throwError } from 'rxjs';

export class HttpErrorResponseShowed extends HttpErrorResponse {
  static clone(target: HttpErrorResponse): HttpErrorResponseShowed {
    return new HttpErrorResponseShowed({
      error: target.error,
      url: target.url || undefined,
      status: target.status,
      headers: target.headers,
      statusText: target.statusText,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarCommonApiErrorInterceptor implements HttpInterceptor {
  constructor(private readonly snackBar: SnackBarService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(req)
      .pipe(
        catchError((error) => throwError(() => this.handleCommonErrors(error)))
      );
  }

  protected handleCommonErrors<T = unknown>(
    error: T
  ): HttpErrorResponseShowed | T {
    if (error instanceof HttpErrorResponse) {
      const message = this.decodeCommonErrorMessages(error);

      if (message) {
        this.snackBar.showDangerMessage(message);
        return HttpErrorResponseShowed.clone(error);
      }
    }

    return error;
  }

  protected decodeCommonErrorMessages(error: HttpErrorResponse): string | null {
    switch (error.status) {
      case 401:
        return 'Сессия истекла';
      case 403:
        return 'Доступ запрещён';
      case 408:
        return 'Превышено время ожидания ответа';
      case 413:
        return 'Превышен лимит размера запроса';
      case 502:
      case 503:
      case 504:
        return 'Сервис не отвечает \n Повторите попытку позже';
    }

    return null;
  }
}
