import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  catchError,
  EMPTY,
  filter,
  interval,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import { take } from 'rxjs/operators';

export type UniqueInRepositoryChecker<T = unknown> = (
  value: T,
  id?: string | null
) => Observable<boolean>;

export function uniqueInRepositoryValidator<T = unknown>(
  source: UniqueInRepositoryChecker<T>,
  idGetter: () => string | null | undefined
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return interval(300).pipe(
      take(1),
      map(() => control.value),
      filter((value) => !!value),
      switchMap((value) =>
        source(value, idGetter()).pipe(
          map((unique) => (unique ? null : { notUnique: true })),
          catchError(() => EMPTY)
        )
      )
    );
  };
}
