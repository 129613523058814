export interface Option<T = string> {
  title: string;
  value: T;
}

export function isOption(input: unknown): input is Option {
  return (
    !!input &&
    typeof input === 'object' &&
    typeof (<any>input).title === 'string' &&
    Object.prototype.hasOwnProperty.call(input, 'value')
  );
}

export function mapRecordToOptions<T extends string = string>(
  record: Record<T, string> | Partial<Record<T, string>> | null | undefined
): Option<T>[] | null {
  if (!record) return null;

  return Object.entries<string>(<Record<T, string>>record).map(
    ([key, title]) => ({
      value: key as T,
      title,
    })
  );
}

export function mapRecordToOptionsRevert<T extends string = string>(
  record: Record<T, string> | Partial<Record<T, string>> | null | undefined
): Option<T>[] | null {
  if (!record) return null;

  return Object.entries<string>(<Record<T, string>>record).map(
    ([key, value]) => ({
      value: value as T,
      title: key,
    })
  );
}
