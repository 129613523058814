import { NgModule } from '@angular/core';
import { ApiBaseUrlInterceptor } from './api-base-url.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthApiService } from './auth/auth-api.service';
import { UserApiService } from './user/user-api.service';
import { ProviderApiService } from './provider/provider-api.service';
import { ServiceApiService } from './service/service-api.service';
import { StorageApiService } from './storage/storage-api.service';
import { OrderApiService } from './order/order-api.service';
import { KanautoCardApiService } from './kanauto-card/kanauto-card-api.service';
import { TextFieldAutocompleteApiService } from '@app/core/api/text-field-autocomplete/text-field-autocomplete-api.service';

@NgModule({
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiBaseUrlInterceptor,
      multi: true,
    },
    AuthApiService,
    UserApiService,
    ProviderApiService,
    ServiceApiService,
    StorageApiService,
    OrderApiService,
    KanautoCardApiService,
    TextFieldAutocompleteApiService,
  ],
})
export class ApiModule {}
