import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const RELOAD_CONTROL_PROVIDER = new InjectionToken(
  'Reload control provider'
);

export interface ReloadControlProvider {
  shouldReloadData(): Observable<boolean>;
}
