export namespace AuthTokenStateActions {
  export class Login {
    static readonly type = '[AuthToken] Login';
    constructor(public readonly token: string) {}
  }

  export class Logout {
    static readonly type = '[AuthToken] Logout';
  }

  export class Impersonate {
    static readonly type = '[AuthToken] Impersonate';
    constructor(public readonly token: string) {}
  }

  export class ClearImpersonation {
    static readonly type = '[AuthToken] Clear Impersonation';
  }
}
