import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  showInfoMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', '_info'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  showSuccessMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', '_success'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  showWarningMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', '_warning'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  showDangerMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', '_danger'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }
}
