import { Inject, Injectable } from '@angular/core';
import {
  RELOAD_CONTROL_PROVIDER,
  ReloadControlProvider,
} from '@app/core/reload-control/reload-control.provider';
import { debounceTime, filter, merge, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadControlService {
  constructor(
    @Inject(RELOAD_CONTROL_PROVIDER)
    private readonly providers: ReloadControlProvider[]
  ) {}

  shouldReload(): Observable<boolean> {
    return merge(...this.providers.map((p) => p.shouldReloadData())).pipe(
      debounceTime(300),
      filter((value) => value)
    );
  }
}
