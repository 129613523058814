import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationDialogData } from './confirmation-dialog/confirmation-dialog-data.interface';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private readonly dialog: MatDialog) {}

  /** отображение модалки подтверждения */
  confirm(
    data: ConfirmationDialogData,
    config: MatDialogConfig = {}
  ): Observable<boolean> {
    return this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        {
          data,
          autoFocus: false,
          restoreFocus: false,
          ...config,
        }
      )
      .afterClosed()
      .pipe(map(Boolean), take(1));
  }
}
