import { NgModule } from '@angular/core';
import { SnackBarService } from './snack-bar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SnackBarCommonApiErrorInterceptor } from './snack-bar-common-api-error-interceptor.service';

@NgModule({
  imports: [MatSnackBarModule],
  providers: [
    SnackBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SnackBarCommonApiErrorInterceptor,
      multi: true,
    },
  ],
})
export class SnackBarModule {}
