import { Option } from '@tes/common';

export function getOptionsFromEnum<T>(
  enumObj: T,
  titleMap?: Record<any, string>
): Option<T>[] {
  if (typeof enumObj !== 'object' || !enumObj) {
    return [];
  }

  return Object.values(enumObj).map((value) => ({
    title: titleMap && value in titleMap ? titleMap[value] : value,
    value,
  }));
}
