import { Injectable } from '@angular/core';
import { LoginRequestDto } from './dtos/login.request.dto';
import { map, Observable } from 'rxjs';
import { LoginResponseDto } from './dtos/login.response.dto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImpersonateResponseDto } from './dtos/impersonate.response.dto';
import { ImpersonateRequestDto } from './dtos/impersonate.request.dto';
import { UserDto } from '@app/core/api/user/dtos';
import { cleanPassword } from '@app/core/api/user/mappers';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  login(request: LoginRequestDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>('/tes-crm/auth/login', request);
  }

  impersonate(
    request: ImpersonateRequestDto
  ): Observable<ImpersonateResponseDto> {
    return this.http.post<ImpersonateResponseDto>(
      '/tes-crm/auth/impersonate',
      request
    );
  }

  getProfile(token?: string | null): Observable<UserDto> {
    let headers = new HttpHeaders();

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    return this.http
      .get<UserDto>('/tes-crm/users/profile', {
        headers,
      })
      .pipe(map(cleanPassword));
  }
}
