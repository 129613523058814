import { Injectable, NgZone, Optional } from '@angular/core';
import {
  ActivatedRoute,
  Params,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { PrimaryOutletPreserveQueryService } from './primary-outlet-preserve-query.service';

/**
 * Управление query параметрами.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationQueryService {
  constructor(
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Optional()
    private readonly primaryOutletPreserveQueryService?: PrimaryOutletPreserveQueryService
  ) {}

  /**
   * Изменить query параметры.
   *
   * В случае параллельных вызовов метода (например, сброс пагинации
   * при изменении фильтра), переадресация может сработать только один раз
   * с параметрами одного из вызовов.
   * Для решения проблемы был добавлен метод navigateDebounced.
   *
   * @param data
   */
  navigate(data: Params): void {
    this.ngZone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: data,
        queryParamsHandling: 'merge',
      })
    );
  }

  /**
   * Работает аналогично методу navigate.
   * При вызове откладывает переадресацию на небольшой таймаут.
   * При параллельных вызовах собирает все объекты data в один объект.
   *
   * Подробнее о проблеме см. описание метода navigate.
   */
  navigateDebounced = this.debounceNavigate(this.navigate.bind(this));

  /**
   * Фильтрует observable изменения query параметров
   * и пропускает только значения, которые изменились в главном аутлете
   * или с вспомогательным аутлетом (в зависимости от аргумента inPrimary).
   *
   * Пример:
   * ```js
   * const queryFilter = this.navQueryService.makeQueryChangesFilter(true);
   *
   * this.activatedRoute.queryParams.pipe(
   *  filter(queryFilter)
   * ).subscribe();
   * ```
   *
   * @param inPrimary - true = пропускать изменения при отсутствии вспомогательных аутлетов.
   */
  makeQueryChangesFilter(inPrimary: boolean): () => boolean {
    if (!this.primaryOutletPreserveQueryService) {
      return () => true;
    }

    return () =>
      this.primaryOutletPreserveQueryService?.inPrimaryNext === inPrimary;
  }

  protected debounceNavigate<
    F extends (data: Params, queryParamsHandling: QueryParamsHandling) => void
  >(fn: F, delay: number = 10) {
    let combinedData = {};
    let timer: number = 0;

    return (data: Params): void => {
      clearTimeout(timer);
      combinedData = {
        ...combinedData,
        ...data,
      };
      setTimeout(() => {
        fn(combinedData, 'merge');
        combinedData = {};
      }, delay);
    };
  }
}
