import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Self,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogData } from './confirmation-dialog/confirmation-dialog-data.interface';
import { ConfirmationService } from './confirmation.service';
import { DestroyService } from '../../utils';

const DEFAULT_CONFIRMATION_DIALOG_DATA: ConfirmationDialogData = {
  question: 'Вы уверены, что хотите удалить?',
  confirmLabel: 'Да, удалить',
  cancelLabel: 'Отменить',
};

/**
 * @Component директива вызова модалки подтверждения действия пользователя
 */
@Directive({
  selector: '[tesConfirmationButton]',
  providers: [DestroyService],
})
export class ConfirmationButtonDirective {
  @Input('tesConfirmationButtonQuestion') question?: string;
  @Input('tesConfirmationButtonConfirmLabel') confirmLabel?: string;
  @Input('tesConfirmationButtonCancelLabel') cancelLabel?: string;

  @Output() confirmed = new EventEmitter<void>();

  constructor(
    private readonly confirmationService: ConfirmationService,
    @Self() private readonly destroy$: DestroyService
  ) {}

  @HostListener('click', ['$event'])
  handleClickEvent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    const data: ConfirmationDialogData = {
      question: this.question || DEFAULT_CONFIRMATION_DIALOG_DATA.question,
      confirmLabel:
        this.confirmLabel || DEFAULT_CONFIRMATION_DIALOG_DATA.confirmLabel,
      cancelLabel:
        this.cancelLabel || DEFAULT_CONFIRMATION_DIALOG_DATA.cancelLabel,
    };

    this.confirmationService
      .confirm(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed) => (confirmed ? this.confirmed.emit() : null));
  }
}
