import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform<T>(target: T[] | string | null | undefined, value: any): boolean {
    return !!target ? target.includes(value) : false;
  }
}
