import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ProviderCreateRequestDto,
  ProviderDto,
  ProviderFindRequestDto,
  ProviderFindResponseDto,
  ProviderUpdateRequestDto,
  ProviderAggregationDto,
  ProviderAttachDocumentsRequestDto,
  ProviderAttachDocumentsResponseDto,
  ProviderDetachDocumentsResponseDto,
  ProviderDetachDocumentsRequestDto,
} from './dtos';
import { map, Observable } from 'rxjs';
import { buildHttpParams } from '@tes/common';
import {
  snackBarApiErrorFallback,
  snackBarSuccessMessage,
} from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: SnackBarService
  ) {}

  /** Поиск поставщиков */
  find<A extends keyof ProviderAggregationDto | never = never>(
    request: ProviderFindRequestDto
  ): Observable<ProviderFindResponseDto<A>> {
    return this.http
      .get<ProviderFindResponseDto<A>>('/tes-crm/provider', {
        params: buildHttpParams(request),
      })
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении списка поставщиков'
        )
      );
  }

  aggregateFind<T extends keyof ProviderAggregationDto>(aggregate: T[]) {
    return (request: Omit<ProviderFindRequestDto, 'aggregate'>) =>
      this.find<T>({
        ...request,
        aggregate,
      });
  }

  aggregateGet<T extends keyof ProviderAggregationDto>(aggregate: T[]) {
    return (providerId: string) =>
      this.find<T>({
        filter: {
          fields: [
            {
              fieldName: 'id',
              fieldType: 'string',
              filterType: 'equals',
              value: providerId,
            },
          ],
        },
        aggregate,
      }).pipe(
        map((result) => result.rows || []),
        map((rows) => rows[0])
      );
  }

  /** Добавление поставщика */
  create(request: ProviderCreateRequestDto): Observable<ProviderDto> {
    return this.http
      .post<ProviderDto>('/tes-crm/provider', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Поставщик успешно сохранён'),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при добавлении поставщика'
        )
      );
  }

  /** Получение поставщика */
  get(id: string): Observable<ProviderDto | null> {
    return this.http
      .get<ProviderDto>(`/tes-crm/provider/${id}`)
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при получении поставщика'
        )
      );
  }

  /** Обновление поставщика */
  update(
    id: string,
    request: ProviderUpdateRequestDto
  ): Observable<ProviderDto> {
    return this.http
      .put<ProviderDto>(`/tes-crm/provider/${id}`, request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Поставщик успешно сохранён')
      );
  }

  /** Удаление поставщика */
  delete(id: string): Observable<ProviderDto> {
    return this.http
      .delete<ProviderDto>(`/tes-crm/provider/${id}`)
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при удалении поставщика'
        )
      );
  }

  /** Добавление нескольких поставщиков */
  createMany(request: ProviderUpdateRequestDto[]): Observable<ProviderDto[]> {
    return this.http
      .post<ProviderDto[]>('/tes-crm/provider/array', request)
      .pipe(
        snackBarSuccessMessage(this.snackBar, 'Поставщики успешно сохранены'),
        snackBarApiErrorFallback(
          this.snackBar,
          'Ошибка при добавлении поставщиков'
        )
      );
  }

  /**
   * Прикрепление документов поставщика.
   *
   * @param providerId
   * @param payload
   */
  attachDocuments(
    providerId: string,
    payload: ProviderAttachDocumentsRequestDto
  ): Observable<ProviderAttachDocumentsResponseDto> {
    return this.http
      .post<ProviderAttachDocumentsResponseDto>(
        `/tes-crm/provider/attachDocuments/${providerId}/`,
        payload
      )
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Не удалось прикрепить документы'
        )
      );
  }

  /**
   * Открепление документов поставщика.
   *
   * @param providerId
   * @param payload
   */
  detachDocuments(
    providerId: string,
    payload: ProviderDetachDocumentsRequestDto
  ): Observable<ProviderDetachDocumentsResponseDto> {
    return this.http
      .post<ProviderDetachDocumentsResponseDto>(
        `/tes-crm/provider/detachDocuments/${providerId}`,
        payload
      )
      .pipe(
        snackBarApiErrorFallback(
          this.snackBar,
          'Не удалось открепить документы'
        )
      );
  }
}
