import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthTokenState } from '../states/auth-token.state';
import { BehaviorSubject, filter, map, Observable, switchMap, tap } from 'rxjs';
import { AuthProfileStateActions } from '../states/auth-profile.state.actions';
import { ProfileModel } from '../models/profile.model';
import { AuthProfileState } from '../states/auth-profile.state';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { AuthTokenStateActions } from '@app/core/auth/states/auth-token.state.actions';

@Injectable({ providedIn: 'root' })
export class AuthService {
  profile$: Observable<ProfileModel | null>;
  hostProfile$: Observable<ProfileModel | null> = this.store.select(
    AuthProfileState.hostProfile
  );

  authenticated$: Observable<boolean>;

  get accessToken(): string | null {
    return this.store.selectSnapshot(AuthTokenState.accessToken);
  }

  protected profileLoadedSubject = new BehaviorSubject(false);
  protected initialized = false;

  constructor(
    private readonly store: Store,
    private readonly authApiService: AuthApiService
  ) {
    this.profile$ = this.profileLoadedSubject.pipe(
      filter((loaded) => loaded),
      switchMap(() => this.store.select(AuthProfileState.profile))
    );

    this.authenticated$ = this.profileLoadedSubject.pipe(
      filter((loaded) => loaded),
      switchMap(() => this.store.select(AuthTokenState.authenticated))
    );
  }

  init(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.store
      .select(AuthTokenState.accessToken)
      .pipe(
        tap(() => this.profileLoadedSubject.next(false)),
        switchMap(() =>
          this.store.dispatch(new AuthProfileStateActions.Load())
        ),
        tap(() => this.profileLoadedSubject.next(true))
      )
      .subscribe();
  }

  impersonate(userId: string): Observable<string> {
    return this.authApiService
      .impersonate({
        clientId: userId,
      })
      .pipe(
        map((response) => response.accessToken),
        tap((token) =>
          this.store.dispatch(new AuthTokenStateActions.Impersonate(token))
        )
      );
  }
}
