import { ProfileModel } from '../models/profile.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthProfileStateActions } from './auth-profile.state.actions';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { AuthTokenState, AuthTokenStateModel } from './auth-token.state';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';

interface AuthProfileStateModel {
  profile: ProfileModel | null;
  hostProfile: ProfileModel | null;
}

const EmptyState: AuthProfileStateModel = {
  profile: null,
  hostProfile: null,
};

@State<AuthProfileStateModel>({
  name: 'profile',
  defaults: { ...EmptyState },
})
@Injectable()
export class AuthProfileState {
  @Selector()
  static profile(state: AuthProfileStateModel): ProfileModel | null {
    return state.profile;
  }

  @Selector([AuthProfileState.profile])
  static userId(
    _: AuthProfileStateModel,
    profile: ProfileModel | null
  ): string | null {
    return profile?.id || null;
  }

  @Selector()
  static hostProfile(state: AuthProfileStateModel): ProfileModel | null {
    return state.hostProfile;
  }

  constructor(
    private readonly authApiService: AuthApiService,
    private readonly store: Store
  ) {}

  @Action(AuthProfileStateActions.Load, { cancelUncompleted: true })
  loadProfile({
    patchState,
  }: StateContext<AuthProfileStateModel>): Observable<unknown> {
    const tokenState =
      this.store.selectSnapshot<AuthTokenStateModel>(AuthTokenState);

    const operations$: Observable<ProfileModel | null>[] = [
      tokenState.accessToken ? this.authApiService.getProfile() : of(null),
      tokenState.hostAccessToken
        ? this.authApiService.getProfile(tokenState.hostAccessToken)
        : of(null),
    ];

    return forkJoin(operations$).pipe(
      tap(([profile, hostProfile]) =>
        patchState({
          profile,
          hostProfile,
        })
      )
    );
  }
}
