import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerRootDirective } from './drawer-root.directive';
import { RELOAD_CONTROL_PROVIDER } from '@app/core/reload-control/reload-control.provider';
import { DrawerReloadControlService } from '@app/core/navigation/drawer/drawer-reload-control.service';

@NgModule({
  declarations: [DrawerRootDirective],
  exports: [DrawerRootDirective],
  imports: [CommonModule],
})
export class DrawerModule {
  static forRoot(): ModuleWithProviders<DrawerModule> {
    return {
      ngModule: DrawerModule,
      providers: [
        DrawerReloadControlService,
        {
          provide: RELOAD_CONTROL_PROVIDER,
          useExisting: DrawerReloadControlService,
          multi: true,
        },
      ],
    };
  }
}
