import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationButtonDirective } from './confirmation-button.directive';
import { ConfirmationService } from './confirmation.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ConfirmationDialogComponent, ConfirmationButtonDirective],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule],
  providers: [ConfirmationService],
  exports: [ConfirmationButtonDirective],
})
export class ConfirmationModule {}
